<script>
import WaveSurfer from 'wavesurfer.js'
//import Slider from "@vueform/slider";

import axios from 'axios';

export default {
  data() {
    return {
      waveSurfer: null,
      value: 0,
      speed: 1,
      currentTime: 0,
      firstClickNavigator: true,
      cssPlayButtonClass: 'ri-play-line',
      cssMuteToggle: 'ri-volume-up-line',
      playBeforeMove: false,
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  mounted: function () {
    this.waveSurferInit();
    if(this.data.episode.urlAudioFile) {
      var vm = this;
      this.$refs.player.onplay = function() {
        vm.cssPlayButtonClass = 'ri-pause-line';
      };
      this.$refs.player.onpause = function() {
        vm.cssPlayButtonClass = 'ri-play-line';
      };
      this.$refs.player.ontimeupdate = function() {
        vm.currentTime = vm.$refs.player.currentTime.toFixed();
      };
      this.$refs.player.addEventListener("timeupdate", this.changedTime, true);
    }
  },
  components: {
    //Slider,
  },
  methods: {
    formatTime: function (seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const secs = Math.floor(seconds % 60);

      return [
          hours.toString().padStart(2, '0'),
          minutes.toString().padStart(2, '0'),
          secs.toString().padStart(2, '0')
      ].join(':');
    },
    playRecord: function() {
      if(this.cssPlayButtonClass === 'ri-pause-line') {
        this.$refs.player.pause();
      } else {
        this.$refs.player.play();
      }
    },
    changedTime: function() {
      if(this.$refs.player) {
        this.value = this.$refs.player.currentTime;
      }
      event.preventDefault();
    },
    endOfRecord: function() {
      this.cssPlayButtonClass = 'ri-play-line';
      this.value = 0;
    },
    setSpeedRate(e) {
      this.speed = e;
      this.$refs.player.playbackRate = e;
    },
    toggleMute() {
      if(this.$refs.player.muted) {
        this.$refs.player.muted = false;
        this.cssMuteToggle = 'ri-volume-up-line';
      }
      else {
        this.$refs.player.muted = true;
        this.cssMuteToggle = 'ri-volume-mute-line';
      }
    },
    moveToLeft(e) {
      var value = this.$refs.player.currentTime - e;
      if(value < 0)
        this.$refs.player.currentTime = 0;
      else
        this.$refs.player.currentTime = value;
    },
    moveToRight(e) {
      var value = this.$refs.player.currentTime + e;
      if(value > this.$refs.player.duration)
        this.$refs.player.currentTime = 0;
      else
        this.$refs.player.currentTime = value;
    },
    // timeLineMove(e) {
    //   this.$refs.player.currentTime = e;
    //   this.value = e;
    //   event.preventDefault();
    // },
    // formatTooltip(v) {
    //   var date = new Date(null);
    //   date.setSeconds(v);
    //   return date.toISOString().substring(11, 19);
    // },
    // beforeMove() {
    //   this.playBeforeMove = this.$refs.player.paused;
    //   if(!this.playBeforeMove)
    //     this.$refs.player.pause();
    // },
    // afterMove() {
    //   if(!this.playBeforeMove)
    //     this.$refs.player.play();
    // },
    waveSurferInit() {
      if(this.data.episode.urlAudioFile && this.data.peaksData) {
        axios.get('/api/episode/get-peaks/' + this.$route.params.id)
        .then((response) => {
          var peaks = response.data;
          if(peaks.length > 0) {
            this.waveSurfer = WaveSurfer.create({
              container: '#waveView_' + this.data.episode.id,
              waveColor: '#abc',
              progressColor: '#383351',
              //url: this.data.episode.urlAudioFile,
              height: 120,
              hideScrollbar: false,
              autoScroll: false,
              media: document.getElementById('episodePlayer'),
              barWidth: 2,
              barGap: 2,
              barRadius: 5,
              normalize: true,
              duration: this.data.episode.stat.seconds,
              peaks: [ peaks ],
            });

            this.waveSurfer.on('ready', this.waveReady);
            this.waveSurfer.on('click', this.waveClick);
            this.waveSurfer.on('finish', this.waveFinish);
          }

        })
        .catch(function (e) {
          console.error("Loading data peaks error:", e);
        });
        
      }
    },
    waveClick() {
      if(this.firstClickNavigator) {
        this.firstClickNavigator = false;
        this.waveSurfer.play();
      }
    },
    waveReady() {
    },
    waveFinish() {
    },
    waveSurfDestroy() {
      if(this.waveSurfer) {
        this.waveSurfer.destroy();
        this.waveSurfer = null;
      }
    },
    shareNavigator() {
      if(window.navigator.canShare) {
        const shareData = {
          title: this.data.episode.title,
          text: this.data.episode.memo,
          url: "https://busycast.com/episode/" + this.data.episode.id,
        };
        window.navigator.share(shareData)
          .then(() => {
            console.log("Shared", shareData);
          })
          .catch(console.error);
      }
    }
  },
  computed: {
    rssFeed: function() {
      return process.env.VUE_APP_URL + 'tools/rss/podcast/' + this.data.podcast.link;
    },
    getSpeedText: function() {
      return this.speed + "x";
    },
    getDurationFromSeconds: function () {
      return this.formatTime(this.data.episode.duration);
    },
    canEditEpisode: function() {
      return localStorage.getItem('user.id') !== null &&
        (this.data.podcast.permissions.isFounder || this.data.podcast.permissions.isAuthor);
    },
    shareNavigatorAbility: function() {
      return window.navigator.canShare;
    }
  }
};
</script>

<template>

  <div class="card team-box ribbon-box ribbon-fill">
    <div class="card-header">
      <div class="d-flex align-items-center">
        <div class="flex-shrink-0 me-2">
          <router-link :to="{ name: 'episode', params: { id: data.episode.id } }">
            <div class="avatar-md flex-shrink-0" v-if="!data.episode.urlAvatar">
              <div class="avatar-title rounded-circle">{{ data.episode.title[0].toUpperCase() }}</div>
            </div>
            <img v-bind:src="data.episode.urlAvatar" alt="" class="avatar-lg img-fluid d-block rounded-circle" v-if="data.episode.urlAvatar">
          </router-link>
        </div>
        <div class="flex-grow-1 ms-2">
            <h5 class="card-title mb-1">
              {{ data.episode.title }}
              <a :href="rssFeed" target="_blank" v-if="data.podcast.share === 2"><i class="ri-rss-line"></i></a>
            </h5>
            <p class="text-muted mb-0">{{ data.episode.short }}</p>
            <router-link :to="{ name: 'podcast', params: { name: data.podcast.link }}" class="text-primary">{{ data.podcast.title }}</router-link>
        </div>
        <div class="flex-shrink-0">          
          <ul class="list-inline d-flex align-items-center mb-0">
            <li class="list-inline-item d-none d-sm-block" v-for="p in data.links" v-bind:key="p">
              <a class="align-middle" target="_blank" :href="p.url" v-if="p.type === 1 && p.url != ''">
                <i class="ri-facebook-line"></i>
              </a>
              <a class="align-middle" target="_blank" :href="p.url" v-if="p.type === 2 && p.url != ''">
                <i class="ri-twitter-line"></i>
              </a>
              <a class="align-middle" target="_blank" :href="p.url" v-if="p.type === 3 && p.url != ''">
                <i class="ri-instagram-line"></i>
              </a>
              <a class="align-middle" target="_blank" :href="p.url" v-if="p.type === 4 && p.url != ''">
                <i class="ri-linkedin-line"></i>
              </a>
              <a class="align-middle" target="_blank" :href="p.url" v-if="p.type === 5 && p.url != ''">
                <i class="ri-github-line"></i>
              </a>
              <a class="align-middle" target="_blank" :href="p.url" v-if="p.type === 6 && p.url != ''">
                <i class="ri-telegram-line"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="align-middle c-hand" v-if="shareNavigatorAbility" @click="shareNavigator">
                <i class="ri-share-line"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <div class="dropdown card-header-dropdown ms-1">
                <a class="text-reset dropdown-btn" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="text-muted fs-16"><i class="mdi mdi-dots-vertical align-middle"></i></span>
                </a>
                <div class="dropdown-menu dropdown-menu-end" style="">
                    <router-link :to="{ name: 'podcast', params: { name: data.podcast.link } }" class="dropdown-item"><i class="ri-eye-fill text-muted me-2 align-bottom"></i> Podcast</router-link>
                    <router-link :to="{ name: 'edit-episode', params: { id: data.episode.id } }" class="dropdown-item" v-if="canEditEpisode"><i class="ri-pencil-line text-muted me-2 align-bottom"></i> Edit</router-link>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="ribbon ribbon-danger" v-if="data.episode.flags.isNew"><small>New</small></div>
      <div :id="'waveView_' + data.episode.id" v-if="data.episode.urlAudioFile && data.peaksData"></div>

      <div class="text-center mt-2 mb-3" v-if="data.episode.urlAudioFile">
          
        <audio id="episodePlayer" ref="player" :src="data.episode.urlAudioFile" preload="none"></audio>
        <!-- <Slider v-model="value" v-bind:tooltips="true" @change="timeLineMove" @start="beforeMove" @end="afterMove" :format="formatTooltip" class="primary-slider mb-3" :max="data.episode.duration" show-tooltip="focus" v-if="false" /> -->

        <b-button variant="light" pill size="sm me-2" class="d-none d-sm-inline">
          {{ formatTime(currentTime) }}
        </b-button>

        <b-button variant="primary" pill size="md me-2" @click="moveToLeft(10)">
          <i class="ri-arrow-go-back-line"></i>
        </b-button>
        <b-button variant="light" pill size="lg me-2" @click="playRecord">
          <i class="fs-1" :class="cssPlayButtonClass"></i>
        </b-button>
        <b-button variant="primary" pill size="md" @click="moveToRight(10)">
          <i class="ri-arrow-go-forward-line"></i>
        </b-button>

        <b-button-group class="ms-2">
          <b-dropdown size="sm" variant="primary rounded-pill" :text="getSpeedText" >
            <b-dropdown-item @click="setSpeedRate(0.5)">0.5</b-dropdown-item>
            <b-dropdown-item @click="setSpeedRate(0.75)">0.75</b-dropdown-item>
            <b-dropdown-item @click="setSpeedRate(1)">1</b-dropdown-item>
            <b-dropdown-item @click="setSpeedRate(1.25)">1.25</b-dropdown-item>
            <b-dropdown-item @click="setSpeedRate(1.5)">1.5</b-dropdown-item>
            <b-dropdown-item @click="setSpeedRate(2)">2</b-dropdown-item>
            <b-dropdown-item @click="setSpeedRate(2.25)">2.25</b-dropdown-item>
            <b-dropdown-item @click="setSpeedRate(2.5)">2.5</b-dropdown-item>
          </b-dropdown>
          <b-button variant="primary rounded-pill ms-2" size="sm" @click="toggleMute">
            <i :class="cssMuteToggle"></i>
          </b-button>
        </b-button-group>

        <b-button variant="light" pill size="sm ms-2" class="d-none d-sm-inline">
          {{ getDurationFromSeconds }}
        </b-button>
        
      </div>

      <p class="pre-line m-0" v-if="data.episode.memo">{{ data.episode.memo }}</p>
      <div class="d-flex flex-wrap gap-2 fs-16 mt-3" v-if="data.tags != null && data.tags.length > 0">
        <div class="badge fw-medium badge-soft-light" v-for="p in data.tags" v-bind:key="p">
          <router-link :to="{ name: 'episodes-tag', params: { value: p.title } }">
          #{{ p.title }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
